import React from 'react'
import Categories from '../components/categories'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ProjectsDirectoryHeader, ProjectsTable } from '../components/projects'

const ResourceCategoryProjectTemplate = ({ data }) => {

  const tableData = data.allStrapiResource.edges.map(x => {
    return {
      id: x.node.id,
      name: x.node.name,
      grant: x.node.grant,
      github_link: x.node.github_link,
      website_link: x.node.website_link,
      subsocial_link: x.node.subsocial_link,
      email: x.node.email,
      short_description: x.node.short_description,
      logo: x.node.logo
    }
  })

  const categories = data.allStrapiResourceCategory.edges.map(x => {
    return {
      id: x.node.id,
      name: x.node.name,
      slug: x.node.slug
    }
  })

  return (
    <Layout>
      <SEO title="Projects" />
      <div className="mgt-medium column">
        <div className="column">
          <ProjectsDirectoryHeader Name="Projects Directory" IsProjects={false} />
          <Categories Categories={categories} chosenCategory={data.strapiResourceCategory.slug} />
        </div>
        <ProjectsTable data={tableData} />
      </div>
    </Layout>
  )
}
export default ResourceCategoryProjectTemplate

export const query = graphql`  
  query ResourceCategoryProjectTemplate($name: String!) {
    strapiResourceCategory(name: {eq: $name}) {
      name
      slug
    }
    allStrapiResource(filter: {resource_category: {name: {eq: $name}}}) {
      edges {
        node {
          id
          name
          grant
          github_link
          website_link
          subsocial_link
          email
          short_description
          logo {
            childImageSharp {
              fixed(width: 40, height: 40) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allStrapiResourceCategory {
      edges {
          node {
              id
              name
              slug
          }
      }
    }
  }
`